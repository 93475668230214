import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [TranslateModule],
  selector: 'app-loader',
  template: `
    <div
      [class.content-container-card]="useContentContainerClass"
      class="container-fluid loader-container"
    >
      @if (showMessage) {
        <h3>
          {{ message || 'LOADING' | translate }}
        </h3>
      }

      <div class="spinner-border" role="status"></div>
    </div>
  `,
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input() message?: string;
  @Input() showMessage = true;
  @Input() useContentContainerClass = true;
}
